//*************************************************************
// Navigation
//*************************************************************
.navigation-open{
	#bodycontainer{
		@media(max-width: (@breakpoint_menu_colapsed - 1px)){
			&::before{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				background: @color-menuoverlay;
				z-index: 2;
				transition: all 200 ease-in-out;
			}	
		}
	}		
}
#navigation_cont{
	position: absolute;
	display: block;
	left:0;
	right:0;
	z-index: 1000;
	text-align: center;
	top:15px;
	@media(min-width: (@breakpoint_header-5)){
		top:20px;	
	}
	@media(min-width: (@breakpoint_tablet_small)){
		top:30px;	
	}
	@media(min-width: (@breakpoint_tablet_large)){
		top:50px;
	}
	#navigation{
		display: none;
		.navigation-open &{
			display: block;	
		}
		@media(min-width: (@breakpoint_menu_colapsed)){
			display: block;	
		}
		ul{
			position: relative;
			display: block;
			max-width: 400px;
			margin-right: auto;
			margin-left: auto;
			@media(min-width: (@breakpoint_menu_colapsed)){
				max-width: none;
			}
			li{
				display: block;
				padding-bottom: 2px;
				@media(min-width: (@breakpoint_menu_colapsed)){
					display: inline-block;	
				}
				@media(min-width: (@breakpoint_tablet_large)){
					padding-left: 5px;
					padding-right: 5px;
				}				
				a{
					text-decoration: none;
					text-align: left;
					display: block;
					//font-weight: bold;
					box-sizing: border-box;
					background: @color-menulr-background;
					color: @color-menulr;
					font-size:18px;
					padding: 10px 15px 10px 15px;
					&:hover{
						background: @color-menulr-hov-background;
						color: @color-menulr-hov;
						font-weight: 500;
					}
					@media(min-width: (@breakpoint_menu_colapsed)){
						background: @color-menu-background;
						color: @color-menu;
						font-size:20px;
						opacity: 0.8;
						&:hover{
							background: @color-menu-hov-background;
							color: @color-menu-hov;
							opacity: 1.0;
							font-weight: 300;
							//.multiple-dropshadow(@stin-popupbutton-hover);
							span.menu-background-hover{
								display: inline-block;
								padding-bottom: 8px;
								background-size: 127px;
								.svg-background-img(vlnovka-white);
								background-position: bottom center;
								background-color: transparent;
								background-repeat: repeat-x;
							}
						}
					}
					@media(min-width: (@breakpoint_tablet_large)){
						font-size:24px;
					}
					
				}
			}	
		}	
	}		
	//@media(min-width: @breakpoint_tablet_small){		
	
	//}
		
	
}
#navigation_toggle{
	display:block;
	width: 38px;
	height: 38px;
	position: relative;
	background-size: cover;
	background-position: center center;
	background-color: transparent;
	background-repeat: no-repeat;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
	.svg-background-img(menu-icon);
	.border-top-radius(5px);
	opacity: 0.7;
	&:hover{
		opacity: 1;
	}
	.navigation-open &{
		opacity: 1;
		.svg-background-img(menu-close);
		background-size: 70%;
		background-color: @color-menulr-background;
		&:hover{
			background-size: 80%;
			.svg-background-img(white-close);
			//background-color: @color-menulr-hov-background;
		}
	}
	@media(min-width: (@breakpoint_menu_colapsed)){
		display: none;	
	}
}