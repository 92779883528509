//*************************************************************
// Popup
//*************************************************************
.overlay-cont{
	&::before{
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: rgba(0,0,0,0.7);
		z-index: 2;
		transition: all 200 ease-in-out;
	}
	&.overlaylight::before{
		background: rgba(235,235,235,0.7);
	}
}
.popup { 
	position: absolute; 
	left: 0; 
	top: 0; 
	right: 0; 
	bottom: 0; 
	color: #00002b; 
}
.popupc { 
	display: none; 
	overflow: auto; 
	max-width: 600px; 
	width: calc(100% - 10px); 
	max-height: 400px; 
	height: calc(100% - 40px);
	//height: auto; 
	position: fixed; 
	z-index: 5000; 
	left: 50%; 
	top: 50%; 
	.multiple-transform(translateX(-50%) translateY(-50%)); 
	filter: blur(0);
	background-color: white; 
	border-radius: 15px; 
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); 
	transition: all 200 ease-in-out;
	&.popup-window-appdownload{
		max-width: 400px;
		text-align: left;
	}
	&.popup-on{
		display: block;	
	}
}

.popupcontent { 
	position: relative; 
	left: 0; 
	top: 0; 
	right: 0; 
	bottom: 0; 
}
.popclosec { 
	position: fixed; 
	right: 5px; 
	top: 5px; 
	z-index: 200; 
}
.popclose { 
	width: 24px; 
	height: 24px; 
	float: left; 
	display: block; 
}
.popcloseicon { 
	width: 14px; 
	height: 14px; 
	float: left; 
	display: block; 
	margin-left: 5px; 
	margin-top: 5px; 
	.svg-background-img(red-close);
}
#popup_close{
	&:hover{
		.popcloseicon{
			width: 18px;
			height: 18px;
			margin-left: 3px;
			margin-top: 3px;
		}	
	}	
}
.popbody{
	padding-right:24px;	
	padding-left:24px;
	padding-top:24px;	
	box-sizing: border-box;
	
}
.popup-app-info{
	padding-left: 60px;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: 48px;
	&.toysapp-icon{
		background-image: url('images/icon-toys.png');	
	}
	&.halloweenapp-icon{
		background-image: url('images/icon-heloween.png');
	}
}
.popup-app-buttons{
	padding-top: 16px;
	border-top: 1px dotted @grey-battleship;
	.appbutton-href{
		margin-bottom: 15px;
		max-width: 170px;
		.multiple-dropshadow(@stin-popupbutton);
		@media(min-width: (@breakpoint_mobil_small - 40px)){
			max-width: 150px;
			display: inline-block;
			&.appbutton-no1{
				margin-right: 12px;	
			}
			//margin-bottom: 0px;
		}
		@media(min-width: @breakpoint_mobil_small){
			max-width: 150px;
		}
		@media(min-width: @breakpoint_mobil_large){
			max-width: 150px;
		}
		@media(min-width: @breakpoint_tablet_small){
			max-width: 150px
		}
		@media(min-width: @breakpoint_tablet_large){
			max-width: 150px;
		}
		&:hover{
			.multiple-dropshadow(@stin-popupbutton-hover);	
		}
	}
}
