//*************************************************************
// Header
//*************************************************************
// paddings
@header-padd-top-1:21%;//100px;
@header-padd-bottom-1:6%;

@header-padd-top-2:22%;//130px;
@header-padd-bottom-2:8%;

@header-padd-top-3:25%;//200px;
@header-padd-bottom-3:5%;

@header-padd-top-4:22%;//200px;
@header-padd-bottom-4:5%;

@header-padd-top-5:21%;
@header-padd-bottom-5:10%;

@header-padd-top-6:19%;
@header-padd-bottom-6:7%;

@header-padd-top-7:21%;
@header-padd-bottom-7:6%;

@header-padd-top-8:17%;
@header-padd-bottom-8:6%;

@header-padd-top-9:17%;
@header-padd-bottom-9:6%;

@header-padd-top-final:21%;
@header-padd-bottom-final:6%;

#headerdiv_cont{
	position:relative;
	text-align:center;
	//padding-bottom:@section-pseudoelements-height1;
	//min-height: 100vh;
	#h1_wrapper{
		box-sizing: border-box;
		margin-right:auto;
		margin-left:auto;
		text-align:center;
		position: relative;
		//width:80%;
		//max-width: 906px;
		//height: 100vh;
		//padding-bottom: 0,6%;
		padding-top:@header-padd-top-1;
		padding-bottom:@header-padd-bottom-1;

		@media(min-width: (@breakpoint_header-2)){
			padding-top:@header-padd-top-2;
			padding-bottom:@header-padd-bottom-2;
		}
		
		@media(min-width: (@breakpoint_header-3)){
			padding-top:@header-padd-top-3;
			padding-bottom:@header-padd-bottom-3;
		}
		
		@media(min-width: (@breakpoint_header-5)){
			padding-top:@header-padd-top-4;
			padding-bottom:@header-padd-bottom-4;
		}
		
		@media(min-width: (@breakpoint_header-6)){
			padding-top:@header-padd-top-5;
			padding-bottom:@header-padd-bottom-5;
		}
		
		@media(min-width: (@breakpoint_tablet_small)){
			padding-top:@header-padd-top-2;
			padding-bottom:@header-padd-bottom-2;
		}
		
		@media(min-width: (@breakpoint_header-8)){
			padding-top:@header-padd-top-6;
			padding-bottom:@header-padd-bottom-6;
		}
		
		@media(min-width: (@breakpoint_tablet_large)){
			padding-top:@header-padd-top-7;
			padding-bottom:@header-padd-bottom-7;			
		}
		
		@media(min-width: (@breakpoint_header-9)){
			padding-top:@header-padd-top-8;
			padding-bottom:@header-padd-bottom-8;			
		}
		
		@media(min-width: (@breakpoint_desktop)){
			padding-top:@header-padd-top-9;
			padding-bottom:@header-padd-bottom-9;			
		}
		
		@media(min-width: (@breakpoint_header-final)){
			padding-top:@header-padd-top-final;
			padding-bottom:@header-padd-bottom-final;			
		}
		
	}
	#h1_cont{
		box-sizing: border-box;
		position: relative;
		@media(min-width: (@breakpoint_mobil_large - 60px)){
			//width: 60%;
			//float: left;
		}
		@media(min-width: (@breakpoint_tablet_small)){
			//width: 50%;
		}
		h1{
			display: block;
			text-align: center;
			//margin:0;
			//padding:0;
			position: relative;
			@media(min-width: @breakpoint_header-3){	
				font-size: 44px;
			}
			@media(min-width: @breakpoint_header-4){	
				font-size: 46px;
			}
			@media(min-width: @breakpoint_header-4){	
				font-size: 48px;
			}
			@media(min-width: @breakpoint_header-6){	
				font-size: 52px;
			}
			@media(min-width: @breakpoint_header-7){	
				font-size: 64px;
			}
			@media(min-width: @breakpoint_mobil_large){	
				font-size: 70px;
			}
			@media(min-width: @breakpoint_tablet_small){	
				font-size: 90px;
			}
			@media(min-width: @breakpoint_tablet_large){	
				font-size: 110px;
			}
			@media(min-width: @breakpoint_desktop){	
				font-size: 135px;
			}
			
		}
	}
}

#logobackground_cont{
	top: 0;
	.svg-background-img(wattsenglish-podkladloga);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	display: block;
	position: absolute;
	width:132%;
	padding-bottom: (1137/1148)*132%;
	left:-16%;
	right:-16%;
	margin-top: -2%;
	@media(min-width: @breakpoint_header-4){
		margin-top: -4%;		
	}
	@media(min-width: (@breakpoint_header-5)){
		width:124%;
		padding-bottom: (1137/1148)*124%;
		left:-12%;
		margin-top: -6%;		
	}
	@media(min-width: (@breakpoint_header-6)){
		width:120%;
		padding-bottom: (1137/1148)*120%;
		left:-10%;
		margin-top: -8%;		
	}
	@media(min-width: (@breakpoint_mobil_large)){
		width:118%;
		padding-bottom: (1137/1148)*118%;
		left:-9%;
		margin-top: -10%;		
	}
	@media(min-width: (@breakpoint_tablet_small)){
		width:116%;
		padding-bottom: (1137/1148)*116%;
		left:-8%;
		margin-top: -10%;	
	}		
		
	@media(min-width: (@breakpoint_tablet_large)){
		width:110%;
		padding-bottom: (1137/1148)*110%;
		left:-5%;
		margin-top: -8%;
	}
		
	@media(min-width: (@breakpoint_header-9)){
		width:104%;
		padding-bottom: (1137/1148)*104%;
		left:-2%;
		margin-top: -8%;
	}
	
	@media(min-width: (@breakpoint_header-10)){
		width:100%;
		padding-bottom: (1137/1148)*100%;
		left:0;
		margin-top: -8%;
	}
	
	@media(min-width: (@breakpoint_header-final)){
		width:1500px;
		padding-bottom: (1137/1148)*1500px;
		left:0;
		right:0;
		margin-left: auto;
		margin-right: auto;
		margin-top: -134px;	
	}
}

@header-text-max-width-1:272px;
@header-text-max-width-2:304px;
@header-text-max-width-3:320px;
@header-text-max-width-4:400px;
@header-text-max-width-5:430px;
@header-text-max-width-final:450px;

p#introduction_text{
	color: white;
	width:80%;
	margin-left: auto;
	margin-right: auto;
	//text-align: justify;
	min-height: 184px;
	max-width: @header-text-max-width-1;
	margin-bottom: 10%;
	@media(min-width: @breakpoint_header-1){
		margin-bottom: 15%;
	}
	@media(min-width: @breakpoint_header-2){
		margin-bottom: 20%;	
	}
	@media(min-width: @breakpoint_header-3){
		margin-bottom: 25%;	
	}
	@media(min-width: @breakpoint_header-6){
		min-height: 0;
		margin-bottom: 25%;
		max-width: @header-text-max-width-2;
	}
	@media(min-width: @breakpoint_header-7){
		font-size: 20px;
		max-width: @header-text-max-width-3;
	}
	@media(min-width: @breakpoint_tablet_small){
		font-size: 22px;
	}
	@media(min-width: @breakpoint_header-8){
		margin-bottom: 40px;
		max-width: @header-text-max-width-4;
	}
	@media(min-width: @breakpoint_header-9){
		//margin-bottom: 40px;
		max-width: @header-text-max-width-5;
		font-size: 24px;
	}
	@media(min-width: @breakpoint_desktop){
		font-size: 26px;
	}
	@media(min-width: @breakpoint_header-final){
		font-size: 28px;
		max-width: @header-text-max-width-final;
	}
	&::after{
		display:block;
		content:'';
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		pointer-events: none;
		//.svg-background-img(white-starsign);
		height: 20px;
		margin-top: 20px;
		@media(min-width: @breakpoint_header-1){
			.svg-background-img(white-starsign);		
		}
		@media(min-width: @breakpoint_header-4){
			margin-top: 30px;	
		}
		@media(min-width: @breakpoint_header-7){
			margin-top: 40px;
			height: 30px;
		}
		@media(min-width: @breakpoint_header-final){
			margin-top: 40px;
			height: 50px;
		}
	}
}

p#partnership_text{
	//color: white;
	width:80%;
	margin-left: auto;
	margin-right: auto;
	//text-align: justify;
	//margin-bottom: 10%;
	max-width: @header-text-max-width-1;
	@media(min-width: @breakpoint_header-6){
		max-width: @header-text-max-width-2 - 20px;
	}
	@media(min-width: @breakpoint_header-7){
		font-size: 20px;
	}
	@media(min-width: @breakpoint_tablet_small){
		font-size: 22px;
	}
	@media(min-width: @breakpoint_header-8){
		color: white;
		max-width: @header-text-max-width-4 - 20px;
		margin-bottom: 20%;
	}
	@media(min-width: @breakpoint_header-9){
		max-width: @header-text-max-width-5 - 20px;
		margin-bottom: 18%;
		font-size: 24px;
	}
	@media(min-width: @breakpoint_desktop){
		font-size: 26px;
	}
	@media(min-width: @breakpoint_header-11){
		margin-bottom: 26%;
	}
	@media(min-width: @breakpoint_header-final){
		font-size: 28px;
		max-width: @header-text-max-width-final - 20px;
		margin-bottom: 202px;
	}
}

