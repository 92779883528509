//*************************************************************
// wowenglish
//*************************************************************
#wowenglish_cont{
	//min-height: 100vh;
	background-color: @body-background;
	//background-image: url('images/background-playground.jpg');
	//background-position: center bottom;
	//background-size: cover;
	text-align:center;
	color: black;
	position:relative;
	padding-top: @section-padd-top-1;
	//padding-bottom: @section-padd-bottom-1;	
	@media(min-width: @breakpoint_tablet_large){
		padding-top: @section-padd-top-2;
		//padding-bottom: @section-padd-bottom-2;
	}
	h2{
		margin-bottom: 30px;	
		@media(min-width: (@breakpoint_mobil_small)){
			margin-bottom: 36px;
		}
		@media(min-width: (@breakpoint_tablet_large)){
			margin-bottom: 36px;
		}
	}
	p{
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		@media(min-width: (@breakpoint_tablet_large - 60px)){
			max-width: 70%;
		}
	}
	.h2-image{
		img{
			max-width:70%;
			@media(min-width: (@breakpoint_mobil_small - 70px)){
				max-width:250px;
			}
			@media(min-width: (@breakpoint_tablet_small)){
				max-width:300px;
			}
			@media(min-width: (@breakpoint_tablet_large)){
				max-width:300px;
			}
		}	
			
	}
}