// Breakpointy
@breakpoint_docfotogalery: 440px;
@breakpoint_mobil_small: 480px;
@breakpoint_bottombreak1: 530px;
@breakpoint_bottombreak2: 570px;
@breakpoint_inputfloats: 600px;
@breakpoint_mobil_large: 640px;
@breakpoint_tablet_small: 720px;
@breakpoint_menu_colapsed: 720px;
@breakpoint_tablet_large: 992px;
@breakpoint_desktop: 1220px;

@breakpoint_height-1: 640px;
@breakpoint_height-2: 768px;
@breakpoint_height-3: 900px;
@breakpoint_height-4: 1024px;
@breakpoint_height-5: 1280px;

@breakpoint_header-1: 334px;
@breakpoint_header-2: 350px;
@breakpoint_header-3: 360px;
@breakpoint_header-4: 430px;
@breakpoint_header-5: 460px;
@breakpoint_header-6: 520px;
@breakpoint_header-7: 580px;
@breakpoint_header-8: 880px;
@breakpoint_header-9: 1090px;
@breakpoint_header-10: 1340px;
@breakpoint_header-11: 1400px;
@breakpoint_header-final: 1500px;

// Barvy
@orange-bright: #ff6600;
@orange-lighter: #f76b1c;
@orange-darker: #de5508;

@grey-battleship: #727281;
@grey-pale: #f1f1f9;
@grey-pale-2: #dadaea;

@blue-darknavy: #00002b;
@blue-dusk: #43436c;
@blue-verylight: #dfe8fa;
@blue-blue: #1919dd;
@blue-greylight: #cbcbcf;
@blue-cloudy: #b0b0d9;

@red-cherry: #df003a;
@pink-light: #fff0f4;

@green-darkish: #27893C;
@green-ice: #e5f0ea;

// Barvy dle poslání
@light-box-background: white;
@footer-background: #0D253F;
@body-background: white;//#348ab9;
@gradient-color2: #ee162d;
@gradient-color1: lighten(@gradient-color2,15%);
@h1_color: black;//#fdca00;
@topdiv_h2_color: black;

// Barvy SVG
@svg-orange: #FF6600;
@svg-darksteelblue: @blue-dusk;
@svg-green: @green-darkish;
@svg-lightsteelblue: #B0B0D9;


//Prednastaveni stinu
@stin-sekce-offset_x: 0; 
@stin-sekce-offset_y: 0;
@stin-sekce-blur_radius: 6px;
@stin-sekce-spread_radius: 0;
@stin-sekce-color: #222;

@stin-vyrazne-color: #bbbbbb;

@stin-obrazku-offset_x: 0; 
@stin-obrazku-offset_y: 0;
@stin-obrazku-blur_radius: 6px;
@stin-obrazku-spread_radius: 0;
@stin-obrazku-color: #bbbbbb;

@stin-topvideo-settings:  0 0 0.75rem rgba(0,0,0,0.6);
@stin-hover-settings:  0 0 0.8rem rgba(0,0,0,0.9);

@stin-popupbutton:  0 0 2px rgba(0,0,0,0.4);
@stin-popupbutton-hover:  0 0 3px rgba(0,0,0,0.8);

//Prednastaveni pisem
@header-font: "Quicksand", "Helvetica Neue", Helvetica, Arial, sans-serif;
@body-font: "Quicksand", "Helvetica Neue", Helvetica, Arial, sans-serif;
//@body-font: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@handwrite-font: cursive;

//Foot
@footavatar-width:40px;

@footer-height-1: 50px;
@footer-height-2: 336px;
@footer-height-3: 192px;
@footer-height-4: 196px;
@footer-height-5: 225px;

// Ikony
@icon-width1:16px;
@icon-width2:24px;
@icon-width3:32px;
@decimal-star-shift1: 0.2px;
@decimal-star-shift2: 1px;

// HP
@hilight-cont-width1: 190px;
@hilight-cont-width2: 216px;
@highlight-icon-shift1: -5px;
@highlight-icon-shift2: -5px;
@decimal-star-shift: 2px;

// Sekce s okrajem
@section-pseudoelements-background1:2335px;
@section-pseudoelements-height1: 60px;
@section-pseudoelements-background2:2535px;

@section-padd-top-1: 80px;
@section-padd-bottom-1: 70px;

@section-padd-top-2: 120px;
@section-padd-bottom-2: 120px;

// Watch
@color-en: #fcce2f;
@color-es: #00abec;
@color-de: #ee162d;
@color-pt: #aec923;
@color-fr: #ec7d1d;
@color-it: @color-en;
@color-ar: #068bb5;
@color-hi: #A62F38;

@stin-slider:  0 0 4px rgba(0,0,0,0.4);

// Navigation
@color-menuoverlay: rgba(0,0,0,0.65);//rgba(245,245,245,0.7);

@color-menulr-background: rgba(238,22,45,0.95);
@color-menulr-hov-background: rgba(238,22,45,1.0);
@color-menulr: white;
@color-menulr-hov: white;

@color-menu-background: transparent;
@color-menu-hov-background: transparent;
@color-menu: white;
@color-menu-hov: @color-menu;

// Play
@playimage-breakpoint: @breakpoint_desktop;

// Listen
@mraky-show: 1220px;
@maggie-show: 1220px;
@maggie-breakpoint: 1760px;