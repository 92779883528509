//*************************************************************
// CONTACTS
//*************************************************************
#contacts_cont{
	//background-color: white;
	//background-image: url('images/background-topdiv.jpg');
	//background-position: center top;
	//background-repeat: no-repeat;
	//background-size: cover;
	position: relative;
	color:black;
	
	padding-top: @section-padd-top-1;
	//padding-bottom: @section-padd-bottom-1;	
	@media(min-width: @breakpoint_tablet_large){
		padding-top: @section-padd-top-2;
		//padding-bottom: @section-padd-bottom-2;
	}
	h3{
		font-weight: 500;
		//text-transform: uppercase;
		margin-top: 40px;
		margin-bottom: 0px;
		@media(min-width: @breakpoint_tablet_large){
			margin-top: 50px;
		}
	}
	p{
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		@media(min-width: (@breakpoint_tablet_large - 60px)){
			max-width: 70%;
		}
	}
	// PDFDOWNLOADS
	.pdfdownloads-cont{
		margin-bottom: 30px;
		max-width: 100%;
		.pdfdownload{
			text-decoration: none;
			display: inline-block;
			position: relative;
			margin: 10px;
			font-size: 14px;
			box-sizing: border-box;
			width: 60px;
			height: 60px;//(152/165) * 60px;
			line-height: 60px;//(152/165) * 60px;
			font-weight: 500;
			//text-shadow: 0 0 2px rgba(0,0,0,0.6);
			//color: lighten(@gradient-color2, 35%);
			color:white;
			//.multiple-dropshadow(@stin-popupbutton);
			@media(min-width: @breakpoint_mobil_large){	
			   	font-size: 18px;
			   	width: 100px;
				height: 100px;//(152/165) * 100px;
				line-height: 100px;//(152/165) * 100px;
			}
			//@media(min-width: @breakpoint_tablet_small){	
			   	//font-size: 26px;
			//}
			@media(min-width: @breakpoint_tablet_large){	
				//font-size: 27px;
				margin: 15px;
			}
			//@media(min-width: (@breakpoint_tablet_large + 200px)){	
				//font-size: 28px;
			//}
			&::before{
				position: absolute;
				content:'';
				height: 100%;
				width: 100%;
				background-color: @gradient-color2;
				border-radius: 50%;
				display: block;	
				position: absolute;
				content: '';
				left:0;
				right:0;
				top:0;
				bottom:0;
				margin: auto;
				z-index: -1;
			}
			&:hover{
				color: white;
				//font-weight: 600;
				.multiple-dropshadow(0 0 0.2rem rgba(0,0,0,0.4));
			}
		}
	}
}