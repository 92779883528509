//*************************************************************
// HISTORY
//*************************************************************

#history_cont{
	//min-height: 100vh;
	//background-color: @gradient-color1;
	//.linear-gradient( left, @gradient-color2, @gradient-color1);
	text-align:center;
	position:relative;
	padding-top: @section-padd-top-1;
	padding-bottom: @section-padd-bottom-1;
	color: white;
	@media(min-width: @breakpoint_tablet_large){
		padding-top: @section-padd-top-2;
		padding-bottom: @section-padd-bottom-2;
	}
	h2{
		//color: white;
		
	}
	h3{
	
	}
	p{
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		@media(min-width: (@breakpoint_mobil_large)){
			max-width: 70%;
		}
		@media(min-width: (@breakpoint_tablet_large - 60px)){
			max-width: 60%;
		}
		
	}
}