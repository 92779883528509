// Bootstrap Button Variant

.button-variant(@color; @background; @border) {
	color: @color;
	background-color: @background;
	border-color: @border;

	&:hover,
	&:focus,
	&:active,
	&.active,
	.open .dropdown-toggle& {
		color: @color;
		background-color: darken(@background, 10%);
        border-color: darken(@border, 12%);
	}
	&:active,
	&.active,
	.open .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: @background;
			border-color: @border;
		}
	}

	.badge {
		color: @background;
		background-color: @color;
	}
}

// Background Features

.background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

// Rychle nastaveni kulatych rohu
.border-top-radius(@radius) {
  border-top-right-radius: @radius;
   border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
     border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
   border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
     border-top-left-radius: @radius;
}

// Nastaveni stinu
.our-shadow(@offset_x, @offset_y, @blur_radius, @spread_radius, @color) {
	/* Box Shadow */
    /* Opera */
    -o-box-shadow: @offset_x @offset_y @blur_radius @spread_radius @color;
    /* IE */
    -ms-box-shadow: @offset_x @offset_y @blur_radius @spread_radius @color;
    /* Mozilla FireFox */
    -moz-box-shadow: @offset_x @offset_y @blur_radius @spread_radius @color;
    /* Google Chrome */
    -webkit-box-shadow: @offset_x @offset_y @blur_radius @spread_radius @color;
    /* Normal */
    box-shadow: @offset_x @offset_y @blur_radius @spread_radius @color;
}

// Nastaveni stinu 2
.multiple-shadow(@shadow_settings) {
	/* Box Shadow */
    /* Opera */
    -o-box-shadow: @shadow_settings;
    /* IE */
    -ms-box-shadow: @shadow_settings;
    /* Mozilla FireFox */
    -moz-box-shadow: @shadow_settings;
    /* Google Chrome */
    -webkit-box-shadow: @shadow_settings;
    /* Normal */
    box-shadow: @shadow_settings;
}

.reset-shadow() {
	/* Box Shadow RESET */
    /* Opera */
    -o-box-shadow: none;
    /* IE */
    -ms-box-shadow: none;
    /* Mozilla FireFox */
    -moz-box-shadow: none;
    /* Google Chrome */
    -webkit-box-shadow: none;
    /* Normal */
    box-shadow: none;
}

// Nastaveni filter: drop-shadow
.multiple-dropshadow(@shadow_settings: 0 0 0.75rem black) {
	/* Drop Shadow */
    /* Opera */
    -o-filter: drop-shadow(@shadow_settings); 
    /* IE */
    -ms-filter: drop-shadow(@shadow_settings);
    /* Mozilla FireFox */
    -moz-filter: drop-shadow(@shadow_settings);
    /* Google Chrome */
    -webkit-filter: drop-shadow(@shadow_settings);
    /* Normal */
    filter: drop-shadow(@shadow_settings);
}

// Nastaveni filter: multiple-filter
.multiple-filter(@filter_settings) {
	/* Drop Shadow */
    /* Opera */
    -o-filter: @filter_settings;
    /* IE */
    -ms-filter: @filter_settings;
    /* Mozilla FireFox */
    -moz-filter: @filter_settings;
    /* Google Chrome */
    -webkit-filter: @filter_settings;
    /* Normal */
    filter: @filter_settings;
}

// Nastaveni filter: multiple-transform
.multiple-transform(@transform_settings) {
	/* Transform */
    /* Opera */
    -o-transform: @transform_settings;
    /* IE */
    -ms-transform: @transform_settings;
    /* Mozilla FireFox */
    -moz-transform: @transform_settings;
    /* Google Chrome */
    -webkit-transform: @transform_settings;
    /* Normal */
    transform: @transform_settings;
}

// Font Selections

.body-font() {
	font-family: @body-font;
	font-weight: 300;
}

.heading-font() {
	font-family: @header-font;
	font-weight: 300;
	//text-transform: uppercase;
}

//Nastaveni placeholderu
.our-placeholder-set(@tloustka_pisma; @barva) {
	::-webkit-input-placeholder {
	   .heading-font;
	   font-weight: @tloustka_pisma;
	   color: @barva;
	}
	:-moz-placeholder { /* Firefox 18- */
	   .heading-font;
	   font-weight: @tloustka_pisma;
	   color: @barva;
	}
	::-moz-placeholder {  /* Firefox 19+ */
	   .heading-font;
	   font-weight: @tloustka_pisma;
	   color: @barva;
	}
	:-ms-input-placeholder {  
	   .heading-font;
	   font-weight: @tloustka_pisma;
	   color: @barva;
	}
}

// Vytváření url svg ikon
.svg-background-img(@filename){
	@icondir: '../images/svg/';
	@filepath: '@{icondir}@{filename}.svg';
	background-image:url(@filepath);
}

// Vytváření tříd ikon
.svg-icon-defined(@filename){	
	&.@{filename}{
		.svg-background-img(@filename);	
	}
}

// Transitions single
.transition-single (@time: 0.5s, @property: all, @function: ease) {
	-webkit-transition:@property @time @function;
	-moz-transition:@property @time @function;
	-o-transition:@property @time @function;
	transition:@property @time @function;
}

// Transition multiple
.transition-multiple(...) {
  -webkit-transition: @arguments;
  -moz-transition: @arguments;
  -o-transition: @arguments;
  transition: @arguments;
}