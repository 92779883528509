//*************************************************************
// Common Pages
//*************************************************************
// ** Preload pro obrázky použité v hover ***
body::after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content:url(../images/svg/red-close.svg) url(../images/svg/white-close.svg);
}

.hidden-mobil-small{
	@media (max-width: (@breakpoint_mobil_small - 1px)) {
    	display: none !important;
	}	
}

// ulitba grafikovi
.con1020,
.con1220, 
.con1400,
.con1500, 
.con1600{
	position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1020px;
    width: 100%;
    box-sizing: border-box;
}
.con1220{
    max-width: 1220px;
}
.con1400{
    max-width: 1400px;
}
.con1500{
    max-width: 1500px;
}
.con1600{
    max-width: 1600px;
}

.conmar20{
	position: relative;	
	box-sizing: border-box;
	margin-left: 10px;
	margin-right: 10px;
	@media (min-width: (@breakpoint_mobil_small)) {
		margin-left: 20px;
		margin-right: 20px;	
	}
	
}

.svgicon{
	display: inline-block;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	.svg-background-img(white-starsign);
	width: @icon-width1;
	height:@icon-width1;
	vertical-align: middle;
	
	//LightSteelBlue
	//.svg-icon-defined(lightsteelblue-spec-specification);
	
	&.iconzoom-1{
		width: @icon-width2;
		height:@icon-width2;
		@media(min-width: @breakpoint_tablet_small){		
			width: @icon-width3;
			height:@icon-width3;
		}
	}
}


// ** Hlavní kontejner ***
.pagemain-cont{
	box-sizing: border-box;
	color:white;
	//background-color: @light-box-background;
	position: relative; 
    margin-left: auto; 
    margin-right: auto;
    //margin-bottom: 52px;
    width: 100%;
    min-width: 300px;
    //border-radius: 3px;
    //.border-top-radius(0px); 
    padding-bottom: 0px;
    overflow-x: hidden;
    //height: 100%;
    	
	@media(min-height: (@breakpoint_height-1)){
		min-height: @breakpoint_height-1 - @footer-height-1;
	}
	
	@media(min-height: (@breakpoint_height-2)){
		min-height: @breakpoint_height-2 - @footer-height-1;
	}
	
	@media(min-height: (@breakpoint_height-3)){
		min-height: @breakpoint_height-3 - @footer-height-1;	
	}
	
	@media(min-height: (@breakpoint_height-4)){
		min-height: @breakpoint_height-4 - @footer-height-1;	
	}
	@media(min-height: (@breakpoint_height-5)){
		min-height: @breakpoint_height-5 - @footer-height-1;	
	}	
}

// ** GRID systém ***
.pagemain-row{
	width: 100%;
	box-sizing: border-box;
	&::after{
			content: '';
    		display: block;
    		clear: both;	
	}
	
	// ** Řádka se spodním okrajem
	&.row-border-bottom{
		padding-bottom: 18px;
		border-bottom: 1px solid @grey-pale-2;
		
		@media(min-width: @breakpoint_tablet_small){		
			padding-bottom: 26px;
		}
		@media(min-width: @breakpoint_tablet_large){
			padding-bottom: 40px;
		}
	}
	
	// ** Jednotlivé buňky ***
	.pagemain-cell{
		box-sizing: border-box;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 18px;
		position: relative;
		@media(min-width: @breakpoint_tablet_small){
			padding-left: 24px;
			padding-right: 24px;
			padding-top: 24px;
		}
		@media(min-width: @breakpoint_tablet_large){
			padding-top: 40px;
		}
		&::after{
			content: '';
    		display: block;
    		clear: both;	
		}
		&.cell-border-bottom{
			border-bottom: 1px solid @grey-pale-2;
		}
		&.cell-border-right{
			border-right: 1px solid @grey-pale-2;
		}
		&.cell-border-left{
			border-left: 1px solid @grey-pale-2;
		}
		&.cell-border-top{
			border-top: 1px solid @grey-pale-2;
		}
		&.period-products-toppad1{
			padding-top: 0px;
			@media(min-width: @breakpoint_tablet_large){
				padding-top: 16px;
			}
		}
	}
	.cell-6{
		width: 50%;
		float: left;
	}
	.cell-4{
		width: (100%/3);
		float: left;
	}
	.cell-sm-6,
	.cell-lm-6,
	.cell-st-6,
	.cell-lt-6{
		width: 100%;
		float: none;
	}
	.cell-sm-6{
		@media(min-width: @breakpoint_mobil_small){
			width: 50%;
			float: left;	
		}
	}
	.cell-lm-4{
		@media(min-width: @breakpoint_mobil_large){
			width: (100%/3);
			float: left;	
		}
	}
	.cell-lm-6{
		@media(min-width: @breakpoint_mobil_large){
			width: 50%;
			float: left;	
		}
	}
	.cell-lm-8{
		@media(min-width: @breakpoint_mobil_large){
			width: (100%/3)*2;
			float: left;	
		}
	}
	.cell-st-2{
		@media(min-width: @breakpoint_tablet_small){
			width: (100%/6);
			float: left;	
		}
	}
	.cell-st-3{
		@media(min-width: @breakpoint_tablet_small){
			width: (100%/4);
			float: left;	
		}
	}
	.cell-st-4{
		@media(min-width: @breakpoint_tablet_small){
			width: (100%/3);
			float: left;	
		}
	}
	.cell-st-5{
		@media(min-width: @breakpoint_tablet_small){
			width: (100%/12)*5;
			float: left;	
		}
	}
	.cell-st-6{
		@media(min-width: @breakpoint_tablet_small){
			width: 50%;
			float: left;	
		}
	}
	.cell-st-7{
		@media(min-width: @breakpoint_tablet_small){
			width: (100%/12)*7;
			float: left;	
		}
	}
	.cell-st-8{
		@media(min-width: @breakpoint_tablet_small){
			width: (100%/3)*2;
			float: left;	
		}
	}
	.cell-st-12{
		@media(min-width: @breakpoint_tablet_small){
			width: 100%;
			float: left;	
		}
	}
	.cell-lt-2{
		@media(min-width: @breakpoint_tablet_large){
			width: (100%/6);
			float: left;	
		}
	}
	.cell-lt-3{
		@media(min-width: @breakpoint_tablet_large){
			width: (100%/4);
			float: left;	
		}
	}
	.cell-lt-4{
		@media(min-width: @breakpoint_tablet_large){
			width: (100%/3);
			float: left;	
		}
	}
	.cell-lt-5{
		@media(min-width: @breakpoint_tablet_large){
			width: (100%/12)*5;
			float: left;	
		}
	}
	.cell-lt-6{
		@media(min-width: @breakpoint_tablet_large){
			width: 50%;
			float: left;	
		}
	}
	.cell-lt-7{
		@media(min-width: @breakpoint_tablet_large){
			width: (100%/12)*7;
			float: left;	
		}
	}
	.cell-lt-8{
		@media(min-width: @breakpoint_tablet_large){
			width: (100%/3)*2;
			float: left;	
		}
	}
	.cell-lt-9{
		@media(min-width: @breakpoint_tablet_large){
			width: (100%/4)*3;
			float: left;	
		}
	}
	.cell-dt-4{
		@media(min-width: @breakpoint_desktop){
			width: (100%/3);
			float: left;	
		}
	}
	.cell-dt-6{
		@media(min-width: @breakpoint_desktop){
			width: (100%/2);
			float: left;	
		}
	}
	.cell-dt-12{
		@media(min-width: @breakpoint_desktop){
			width: 100%;
			float: left;	
		}
	}
	&.row-with-even-padding{
		padding-left:8px;	
		padding-right:8px;
		.pagemain-cell{
			padding-left:8px;	
			padding-right:8px;
		}
		@media(min-width: @breakpoint_tablet_small){
			padding-left:12px;	
			padding-right:12px;
			.pagemain-cell{
				padding-left:12px;	
				padding-right:12px;
			}	
		}
	}
	// Offsety
	.st-offset-2{
		@media(min-width: @breakpoint_tablet_small){
			margin-left: (100%/6);
		}
	}
	.st-offset-3{
		@media(min-width: @breakpoint_tablet_small){
			margin-left: (100%/4);
		}
	}
	.lt-offset-0{
		@media(min-width: @breakpoint_tablet_large){
			margin-left: 0;
		}
	}
	.lt-offset-2{
		@media(min-width: @breakpoint_tablet_large){
			margin-left: (100%/6);
		}
	}
	.lt-offset-3{
		@media(min-width: @breakpoint_tablet_large){
			margin-left: (100%/4);
		}
	}
	.lt-offset-4{
		@media(min-width: @breakpoint_tablet_large){
			margin-left: (100%/3);
		}
	}
}

.blockwidth-lt-3{
	@media(min-width: @breakpoint_tablet_large){
		width: (100%/4);
	}	
}

.floating-cleared-box{
	&::after{
			content: '';
    		display: block;
    		clear: both;	
	}
}

// Buttony na šířku
.btn-lr-fullwidth{
	width:100%;
	@media (min-width: @breakpoint_tablet_large) {
		width: auto;
		min-width: 270px;
		&.mw240{
			min-width: 240px;
		}
	}
}
// Padding none
.padding-none{
	padding: 0px !important;	
}
.padding-lt-left-4px{
	padding: 0px !important;
	@media (min-width: @breakpoint_tablet_large) {
		padding-left: 4px !important;
	}
}
// Margins
.margintop-st-20px{
	@media (min-width: @breakpoint_tablet_small) {
		margin-top: 20px;
	}
}
.margintop-dt-20px{
	@media (min-width: @breakpoint_desktop) {
		margin-top: 20px;
	}
}
// State (active, pasive)
.state-active, .state-pasive{
	display: inline-block;
	position: relative;
	padding-left: 13px;
	color: @svg-green;
	&::before{
		box-sizing: border-box;
		content: '';
		width:8px;
		height:8px;
		display: block;
		position: absolute;
		left:0px;
		top:50%;
		margin-top:-4px;
		border-radius: 50%;
		background-color:@svg-green;
	}
}

.state-pasive{
	color: @red-cherry;
	&::before{
		background-color:@red-cherry;
	}
}
//Score state
.score-state-rise, .score-state-fall{
	.body-font();
	font-size: 13px;
	padding-left: 13px;
	position: relative;
	color:@svg-green;
	&::before{
		content: '';
		width:0px;
		height:0px;
		display: block;
		position: absolute;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		left:0px;
		top:6px;
		//background-color:@svg-green;
	}
}
.score-state-rise{
	&::before{
		border-bottom: 5px solid @svg-green;	
	}	
}
.score-state-fall{
	color:@red-cherry;
	&::before{
		border-top: 5px solid @red-cherry;	
	}	
}
// Info, Error a Success box 
.suppl-info-box, 
.suppl-error-box, 
.suppl-success-box{
	border-radius:3px;
	border: 1px solid @blue-dusk;
	background-color:@blue-verylight;
	padding: 9px 10px 9px 33px;
	position: relative;
	font-size:13px;
	line-height: 18px;
	.body-font();
	color: @blue-dusk;
	.svg-background-img(darksteelblue-spec-specification);
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position: 12px 10px;
	.multiple-shadow(0 1px 5px 0 rgba(0, 0, 43, 0.2), 0 3px 1px -2px rgba(0, 0, 43, 0.12), 0 2px 2px 0 rgba(0, 0, 43, 0.14););
	&.supplbox-closing{
		padding-right: 30px;
		.supplbox-closebutton{
			width: 12px;
			height: 12px;
			background-position: center center;
			background-repeat: none;
			background-size: cover;
			.svg-background-img(darksteelblue-close);
			position: absolute;
			display: block;
			right: 10px;
			top:11px;
			cursor: pointer;
		}
	}
}
.suppl-error-box{
	background-color:@pink-light;
	border-color: @red-cherry;
	color: @red-cherry;
	.svg-background-img(red-banner-close);
	&.supplbox-closing{
		.supplbox-closebutton{
			.svg-background-img(red-close);
		}
	}
}
.suppl-success-box{
	background-color:@green-ice;
	border-color: @green-darkish;
	color: @green-darkish;
	.svg-background-img(green-form-ok);
	&.supplbox-closing{
		.supplbox-closebutton{
			.svg-background-img(green-close);
		}
	}
}
.infoboxes-cont{
	& > :first-child{
		margin-top: 8px;
		@media(min-width: @breakpoint_tablet_small){
			margin-top: 12px;		
		}
		@media(min-width: @breakpoint_tablet_large){
			margin-top: 16px;		
		}
	}
}
// Omezení šířky a zvětšení fontu v závislosti na @media width
.lt-mw-300px{
	@media(min-width: @breakpoint_tablet_large){
		max-width:300px;
	}	
}
.lt-mw-564px{
	@media(min-width: @breakpoint_tablet_large){
		max-width:564px;
	}	
}
.lt-mw-66p{
	@media(min-width: @breakpoint_tablet_large){
		max-width: ((100%/3)*2);
	}	
}
.lt-mw-60p{
	@media(min-width: @breakpoint_tablet_large){
		max-width:60%;
	}	
}
.lt-mw-50p{
	@media(min-width: @breakpoint_tablet_large){
		max-width:50%;
	}	
}
.lt-mw-40p{
	@media(min-width: @breakpoint_tablet_large){
		max-width: 40%;
	}	
}
.lt-mw-33p{
	@media(min-width: @breakpoint_tablet_large){
		max-width: (100%/3);
	}	
}
.dt-mw-55p{
	@media(min-width: @breakpoint_desktop){
		max-width:55%;
	}	
}
.dt-mw-50p{
	@media(min-width: @breakpoint_desktop){
		max-width:50%;
	}	
}
.dt-mw-40p{
	@media(min-width: @breakpoint_tablet_large){
		max-width: 40%;
	}	
}
.lt-text-align-right{
	@media(min-width: @breakpoint_tablet_large){
		text-align: right;
	}	
}

p.st-fontsize-up{
	@media(min-width: @breakpoint_tablet_small){
		font-size: 14px;
	}	
}
// Prehravac videa
.playerBlockVideo, .playerBlockYoutube{
	//margin-top:15px;
	//margin-bottom:20px;
	padding: 1% 2.75%  1% 2.75%;
	position: relative;
	
	&::after{
		display: block;
		content: '';
		left: 0;
		top: 0;
		position: absolute;
		.svg-background-img(ramecek-yellow);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		z-index: 13;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	//padding-top: 25px;
	height: 0;
	
	& iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.our-shadow(0; 0; 6px; 0; #222);	
	}
	
}

// Image responsive
img.img-responsive{
	max-width: 100%;
	height: auto;
}

// Text stroke
/* prepare the selectors to add a stroke to */
.stroke-single {
  position: relative;
  background: transparent;
  z-index: 0;
}
/* add a single stroke */
.stroke-single:before {
  content: attr(data-textcontent);
  position: absolute;
  -webkit-text-stroke: 0.1em black;
  left: 0;
  z-index: -1;
  text-align: center;
  width:100%;
}
// Hvezdicka new app
.newappstar{
	display:block;
	position:relative;
	font-size: 12px;
	font-weight:900;
	color:white;
	background: red; 
	width: 40px;
	height: 40px;
	.newappstar-text{
		position: absolute;
		display:block;
		width: 40px;
		height:31px;
		left:0;
		right:0;
		top:0;
		bottom:0;
		margin: auto;
	}
	//.multiple-transform(rotate(20deg));
	&::before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 40px;
		width: 40px;
		background: red;
		.multiple-transform(rotate(30deg));
		z-index: -1;
	}
	&::after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 40px;
		width: 40px;
		background: red;
		.multiple-transform(rotate(-30deg));
		z-index: -1;
	}
}
		
// Obrazkove DIVy
.background-image-cont{
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	display: none;
	pointer-events: none;
}

// Back Top anchor
a#backtop{
	position: fixed;
	right: 15px;
	bottom: 10px;
	width: 26px;
	height: 26px;
	display:block;
	img{
		width: 26px;
		height: 26px;
		opacity: 0.6;
	}
	&::before{
		content: '';
		display: block;
		width: 34px;
		height: 34px;
		position: absolute;
		left:-4px;
		top:-4px;
		background-color: lighten(@gradient-color2, 15%);
		border-radius: 25%;
		z-index: -1;
	}
}

.kotva{
	position: absolute;
	top: -20px;
}

.h2-image{
	text-align: center;
	display: block;
	
}
