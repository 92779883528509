@import "_variables.less";
@import "_mixins.less";
@import "_linear-gradient-mixins.less";

//*************************************************************
// Styly pro WATTSENGLISH.COM
//*************************************************************

// Global Components
* {
margin: 0;
padding: 0;
}

html, body {
	//overflow-x: hidden;
	.body-font;
	width: 100%;
	height: 100%;
	//background:@podklad-body url('images/background-page.jpg') bottom center no-repeat;
	//background-attachment:fixed;
	background-color: @body-background;
	//.background-cover;
	text-align: center;
	box-sizing: border-box;
}
#bodycontainer{
	min-height: 100%;
	position: relative;
	min-width: 320px;
	max-width: 100%;
	overflow: hidden;
}

#main_content{	
	//min-height: 100%;
	//padding-bottom: @footer-height-1;
	position: static;	
	box-sizing: border-box;
}

p{
	.body-font();
	line-height: normal;
	margin-bottom: 14px;
	font-size: 16px;
	color: black;
	@media(min-width: @breakpoint_mobil_large){	
    	font-size: 18px;
    	margin-bottom: 16px;
    }
    #main_content &{
	    font-size: 18px;
		margin-bottom: 16px;
		//font-weight: 900;
		@media(min-width: @breakpoint_mobil_large){	
		   	font-size: 22px;
		   	margin-bottom: 16px;
		   	&.ml-fontweight-bold{
		   		//font-weight: bold;		
		   	}
		}
		@media(min-width: @breakpoint_tablet_small){	
		   	//font-weight: bold;
		   	font-size: 24px;
		   	margin-bottom: 16px;
		}
		@media(min-width: @breakpoint_tablet_large){	
		   	//font-weight: bold;
		   	margin-bottom: 20px;
		}	
    }
    
}
h1, h2, h3, h4{
	.heading-font();    
	//font-weight: normal;
	color: @h1_color;
	margin-left: auto;
	margin-right: auto;	
}
h1{
	color:white;
	font-weight: 700;	
	font-size: 42px;
	position: relative;
	//max-width: 400px;
	//.multiple-dropshadow(@stin-popupbutton);
	@media(min-width: @breakpoint_mobil_small){	
		font-size: 48px;
	}
	@media(min-width: @breakpoint_mobil_large){	
		font-size: 70px;
	}
	@media(min-width: @breakpoint_tablet_small){	
		font-size: 90px;
	}
	@media(min-width: @breakpoint_desktop){	
		font-size: 135px;
	}
}
h2{
	font-size: 30px;
	margin-bottom: 16px;
	//text-transform: uppercase;
	text-align: center;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	max-width: 90%;
	font-weight: 500;
	@media(min-width: @breakpoint_mobil_large){	
    	//font-weight: 900;
    	font-size: 34px;
    	margin-bottom: 16px;
    	&.ml-fontweight-bold{
    		//font-weight: bold;		
    	}
    }
	@media(min-width: @breakpoint_tablet_small){	
    	//font-weight: bold;
    	font-size: 38px;
    	margin-bottom: 16px;
    	max-width: 80%;
    }
	@media(min-width: @breakpoint_tablet_large){	
    	font-size: 42px;
    	//font-weight: bold;
    	margin-bottom: 20px;
    }
    @media(min-width: (@breakpoint_tablet_large + 100px) ){	
    	max-width: 70%;
    }
    &::before{
    	height: 15px;
		width: 15px;
		background-color: @gradient-color2;
		border-radius: 50%;
		display: block;	
		position: absolute;
		content: '';
		left:0;
		right:0;
		margin: auto;
		top:-15px;
		
    }
}
h3{
	font-size: 20px;
	//margin-bottom: 16px;
	margin-top: 40px;
	font-weight: 500;
	@media(min-width: @breakpoint_mobil_large){	
	   	//font-weight: 900;
	   	font-size: 24px;
	   	//margin-bottom: 16px;
	   	&.ml-fontweight-bold{
	   		//font-weight: bold;		
	   	}
	}
	@media(min-width: @breakpoint_tablet_small){	
	   	//font-weight: bold;
	   	font-size: 26px;
	   	//margin-bottom: 16px;
	}
	@media(min-width: @breakpoint_tablet_large){	
	   	//font-weight: bold;
	   	//margin-bottom: 20px;
	   	margin-top: 50px;
	}
}
h4{
	font-size: 16px;
	//margin-bottom: 14px;
	margin-top: 26px;
	font-weight: 500;
	@media(min-width: @breakpoint_mobil_large){	    	
    	font-size: 22px;
    	//margin-bottom: 22px;
    	margin-top: 30px;
    }
	@media(min-width: @breakpoint_tablet_large){	
	   //font-weight: bold;
    }
}
a{
	color:@color-de;	
	text-decoration: none;
}
a:hover{
	text-decoration: underline;
	&.button{
		text-decoration: none;
	}
}
.button {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    //font-weight: 900;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid  transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    border-radius: 10px;
    text-decoration: none;
    background: red;
    color: white;
}

// SLICK
//@import "_incl_slick.less";

// SLICK THEME
//@import "_incl_slick-theme.less";

// NAVIGATION
@import "_incl_navigation.less";

// COMMON PAGES
@import "_incl_commonpages.less";

// HEADER
@import "_incl_header.less";

// HOMEPAGE PAGES
@import "_incl_homepage.less";

// POPUP PAGES
@import "_incl_popup.less";

// FOOTER
@import "_incl_footer.less";