//*************************************************************
// Footer
//*************************************************************
.footback{
	//border-top: 1px solid #303052;
	//position: absolute;
	//left:0px;
	//bottom: 0px;
	position: relative;
	width:100%;
	min-width: 300px;
	//height: @footer-height-1;
	background-color: @gradient-color2;
	//.linear-gradient( top, @gradient-color2, @gradient-color1);
	//color: lighten(@gradient-color2, 30%);
	color:white;
	padding-top: 50px;
	padding-bottom: 50px;
	//.heading-font();
	//.our-shadow(@stin-sekce-offset_x, @stin-sekce-offset_y, @stin-sekce-blur_radius, @stin-sekce-spread_radius, @stin-sekce-color);
	.hornipata{
		//margin-top: (@footer-height-1/2)-15px;
	}
}

// Cookie Police *********
#cookies_bottom_banner{
	text-align: left;
	padding: 14px 46px 14px 14px;
	background-color: white;
	background-color: rgba(200, 200, 200, 0.9);
	position: fixed;
	bottom: 0px;
	right: 0px;
	left: auto;	
	max-width: 600px;
	@media(min-width: 650px){
		border-top-left-radius:14px; 	
	}
	#bb_close{
		display: block;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		.svg-background-img(red-close);
		width: @icon-width1;
		height:@icon-width1;
		position: absolute;
		top:14px;
		right:14px;
		cursor: pointer;
	}
}