//*************************************************************
// PLAY
//*************************************************************
#steveandmaggie_cont{
	position: relative;
	overflow: visible;
	color: white;
	padding-top: @section-padd-top-1;
	//padding-bottom: @section-padd-bottom-1;
	@media(min-width: @breakpoint_tablet_large){
		padding-top: @section-padd-top-2;
		//padding-bottom: @section-padd-bottom-2;
	}
	@media(min-width: @breakpoint_desktop){
		//padding-top: 90px;
		//padding-bottom: 250px;
	}
	h2{
		margin-bottom: 30px;	
		@media(min-width: (@breakpoint_mobil_small)){
			margin-bottom: 36px;
		}
		@media(min-width: (@breakpoint_tablet_large)){
			margin-bottom: 36px;
		}
		
	}
	h3{
		//color: white;		
	}
	p{
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		@media(min-width: (@breakpoint_tablet_large - 60px)){
			max-width: 70%;
		}
	}	
}
#youtubehighlightscont_cont{
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	margin-bottom: 20px;
	max-width: 70%;
	@media(min-width: (@breakpoint_tablet_small - 60px)){
		max-width: 420px;
	}
}
#youtubehighlights_cont{
	box-sizing: border-box;
	position: relative;
	padding-bottom: 100%;
	background-color: @gradient-color2;
	border-radius: 50%;
}
#youtubehighlightstexts_cont{
	position: absolute;
	//left:0; right:0; top:0; bottom: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 130px;
}
.youtubehighlights-texts{
	span{
		display: block;	
	}
	.highlightstext-value{
		font-weight: 700;
		font-size: 24px;
		@media(min-width: (@breakpoint_mobil_small)){
			font-size: 28px;
		}
		@media(min-width: (@breakpoint_mobil_small)){
			font-size: 32px;
		}
	}
	.highlightstext-label{
		font-weight: 500;
		font-size: 14px;
		@media(min-width: (@breakpoint_mobil_small)){
			font-size: 18px;
		}
		@media(min-width: (@breakpoint_mobil_large)){
			font-size: 22px;
		}
	}
}
#subscribers_cont{
	margin-bottom: 16px;
	@media(min-width: (@breakpoint_mobil_small)){
		margin-bottom: 20px;
	}
	@media(min-width: (@breakpoint_mobil_large)){
		margin-bottom: 30px;
	}
}
#youtube_logo{
	max-width:55%;
	@media(min-width: (@breakpoint_mobil_small - 70px)){
		max-width:170px;
	}
	@media(min-width: (@breakpoint_tablet_small)){
		max-width:200px;
	}
	@media(min-width: (@breakpoint_tablet_large)){
		max-width:200px;
	}	
}

